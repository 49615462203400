import * as React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

function LowHoldIcon(props: SvgIconProps) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5}>
        <path d="m5.795 14.306l-1.772-1.775l-1.773 1.775m15.955-4.579l1.772 1.776l1.773-1.776" />
        <path d="M19.977 11.503c0-2.12-.84-4.151-2.336-5.65A7.971 7.971 0 0 0 12 3.513a7.869 7.869 0 0 0-2.97.577a7.977 7.977 0 0 0-4.555 4.75m-.452 3.69a7.997 7.997 0 0 0 1.827 5.082a7.967 7.967 0 0 0 9.966 1.927a7.985 7.985 0 0 0 3.585-4.034" />
        <path d="M9.58 13.978A2.279 2.279 0 0 0 12 16.054c1.952 0 2.42-1.123 2.42-2.076c0-.953-.807-1.963-2.42-1.963s-2.42-.638-2.42-1.938a2.224 2.224 0 0 1 1.537-2.003c.285-.092.585-.125.883-.097a2.329 2.329 0 0 1 2.42 2.1M12 17.264v-1.051m0-9.45v1.21" />
      </g>
    </SvgIcon>
  );
}
export default LowHoldIcon;
