import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

function OddsIcon(props: SvgIconProps) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M5.616 20q-.691 0-1.153-.462T4 18.384V5.616q0-.691.463-1.153T5.616 4h12.769q.69 0 1.153.463T20 5.616v12.769q0 .69-.462 1.153T18.384 20zm0-1h3.382v-3.998H5v3.383q0 .269.173.442t.443.173m4.382 0h4.004v-3.998H9.998zm5.004 0h3.383q.269 0 .442-.173t.173-.442v-3.383h-3.998zM5 14.002h3.998V9.998H5zm4.998 0h4.004V9.998H9.998zm5.004 0H19V9.998h-3.998zM5 8.998h3.998V5H5.615q-.269 0-.442.173T5 5.616zm4.998 0h4.004V5H9.998zm5.004 0H19V5.616q0-.27-.173-.443T18.385 5h-3.383z"
      />
    </SvgIcon>
  );
}

export default OddsIcon;
