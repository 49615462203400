import ArbitrageIcon from "../Icons/ArbitrageIcon";
import CalculatorIcon from "../Icons/CalculatorIcon";
import MiddlesIcon from "../Icons/MiddlesIcon";
import LowHoldIcon from "../Icons/LowHoldIcon";
import EVIcon from "../Icons/EVIcon";
import OddsIcon from "../Icons/OddsIcon";
import ToolsIcon from "../Icons/ToolsIcon";

export const PAGES = [
  {
    title: "odds_screen",
    route: "/odds-screen",
    icon: <OddsIcon />,
  },
  {
    title: "arbitrages",
    route: "/arbitrages",
    icon: <ArbitrageIcon />,
    countKey: "arbitrageCountByLeague",
  },
  {
    title: "ev",
    route: "/expectedvalue",
    icon: <EVIcon />,
    countKey: "expectedValueCountByLeague",
  },
  {
    title: "middles",
    route: "/middles",
    icon: <MiddlesIcon />,
    countKey: "middleCountByLeague",
  },
  {
    title: "free_bet_converter",
    route: "/freebet",
    icon: <CalculatorIcon />,
    countKey: "freeBetCountByLeague",
  },
  {
    title: "low_holds",
    route: "/lowhold",
    icon: <LowHoldIcon />,
    countKey: "lowHoldCountByLeague",
  },
  {
    title: "arbitrage-tool",
    route: "/arbitrage-tool",
    icon: <ToolsIcon />,
  },
];
