import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

function EVIcon(props: SvgIconProps) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M3.616 20q-.691 0-1.153-.462T2 18.384V5.616q0-.691.463-1.153T3.616 4h16.769q.69 0 1.153.463T22 5.616v12.769q0 .69-.462 1.153T20.385 20zm0-1h16.769q.23 0 .423-.192t.192-.424V5.616q0-.231-.192-.424T20.385 5H3.615q-.23 0-.423.192T3 5.616v12.769q0 .23.192.423t.423.192M3 19V5zm7.27-2.5q.213 0 .356-.143t.143-.357t-.143-.357t-.357-.143H6.885v-3h2.384q.214 0 .357-.143T9.769 12t-.143-.357t-.357-.143H6.884v-3h3.385q.214 0 .357-.143T10.769 8t-.143-.357t-.357-.143H6.692q-.348 0-.577.23q-.23.23-.23.578v7.384q0 .349.23.578q.23.23.577.23zm5.153-1.77l-2.094-6.87q-.056-.172-.171-.266t-.292-.094q-.264 0-.41.202t-.079.44l2.41 7.98q.055.17.18.274t.302.104h.308q.177 0 .302-.104t.18-.275l2.41-7.998q.067-.238-.079-.43q-.146-.193-.41-.193q-.176 0-.291.094q-.116.095-.172.266z"
      />
    </SvgIcon>
  );
}

export default EVIcon;
