import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

function ArbitrageIcon(props: SvgIconProps) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} color="currentColor">
        <path d="M14 2.2q-0.97 -0.198 -2 -0.2C6.477 2 2 6.477 2 12s4.477 10 10 10 10 -4.477 10 -10q-0.002 -1.03 -0.2 -2" />
        <path d="M12 9c-1.105 0 -2 0.672 -2 1.5s0.895 1.5 2 1.5 2 0.672 2 1.5 -0.895 1.5 -2 1.5m0 -6c0.87 0 1.612 0.417 1.886 1M12 9v-1m0 7c-0.87 0 -1.612 -0.417 -1.886 -1M12 15v1m4.998 -8.998L21.174 2.824m0.824 3.656 -0.118 -3.09c0 -0.729 -0.435 -1.183 -1.228 -1.24l-3.124 -0.147" />
      </g>
    </SvgIcon>
  );
}

export default ArbitrageIcon;
